import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Link, graphql, StaticQuery } from 'gatsby'

import { Image, RoomItem, RoomList } from '../ui'

function Rooms(props) {
  const { t } = useTranslation()

  return (
    <StaticQuery
      query={graphql`
        query {
          garden: allFile(
            filter: { relativeDirectory: { eq: "rooms/garden" } }
            sort: { fields: name }
            limit: 3
          ) {
            ...multipleFixedImage
          }

          kids: allFile(
            filter: { relativeDirectory: { eq: "rooms/kids" } }
            sort: { fields: name }
            limit: 3
          ) {
            ...multipleFixedImage
          }

          patron: allFile(
            filter: { relativeDirectory: { eq: "rooms/patron" } }
            sort: { fields: name }
            limit: 3
          ) {
            ...multipleFixedImage
          }

          machine: allFile(
            filter: { relativeDirectory: { eq: "rooms/machine" } }
            sort: { fields: name }
            limit: 3
          ) {
            ...multipleFixedImage
          }

          chanPoxila: allFile(
            filter: { relativeDirectory: { eq: "rooms/chan-poxila" } }
            sort: { fields: name }
            limit: 3
          ) {
            ...multipleFixedImage
          }
        }
      `}
      render={(data) => (
        <RoomList columns={{ base: 1, md: 2, xl: 3 }} {...props}>
          {t('rooms:rooms', { returnObjects: true }).map((room) => (
            <RoomItem
              to={room.to}
              as={Link}
              images={data[room.id].edges.map((image) => (
                <Image fixed={image.node.childImageSharp.fixed} layout="background" />
              ))}
              title={room.title}
              desc={room.desc}
            />
          ))}
        </RoomList>
      )}
    />
  )
}

export default Rooms
