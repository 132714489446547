import React from 'react'
import { graphql } from 'gatsby'
import { v4 } from 'uuid'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

import { MdCheck } from 'react-icons/md'
import { IoBed, IoBedSharp } from 'react-icons/io5'

import {
  Stack,
  Box,
  Flex,
  List,
  ListIcon,
  ListItem,
  Button,
  useTheme,
  Divider,
} from '@chakra-ui/react'

import Layout from '../../components/layout'

import {
  Article,
  H1,
  H2,
  Section,
  Gallery,
  Image,
  Hero,
  HeroBody,
  HeroTitle,
  P,
  FeatureList,
  FeatureItem,
  H3,
  FloatingBanner,
  RoomList,
  RoomItem,
  HeroSubtitle,
} from '../../ui'
import ButtonBook from '../../components/buttonBook'
import Rooms from '../../components/rooms'

function RoomsPage({ data }) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Layout title={t('rooms:title')}>
      <Hero
        minHeight={{ base: '250px', md: '400px', lg: '500px' }}
        image={<Image fluid={data.hero.childImageSharp.fluid} layout="background" />}
      >
        <HeroBody>
          <HeroTitle>{t('rooms:title')}</HeroTitle>
          <HeroSubtitle>{t('rooms:subtitle')}</HeroSubtitle>
        </HeroBody>
      </Hero>

      <Article>
        <Section
          title={t('rooms:intro.title')}
          subtitle={t('rooms:intro.subtitle')}
          titleAlign="center"
        >
          <Rooms />
        </Section>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "rooms/garden/HACIENDA_POXILA_300dpi52.jpg" }) {
      ...bannerSectionImage
    }

    gallery: allFile(filter: { relativeDirectory: { eq: "hacienda" } }, sort: { fields: name }) {
      ...multipleFixedImage
    }
  }
`

export default RoomsPage
